<template>
  <v-container style="max-width: 600px; margin: 0 auto">
    <h4>販売業者：</h4>
    株式会社レコセレ
    <h4>代表取締役：</h4>
    邱実
    <h4>所在地：</h4>
    〒104-0053
    <br />
    東京都中央区晴海3-10-1 Daiwa晴海ビル 2F
    <h4>販売URL:</h4>
    https://www.recosele.com
    <!-- <h4>電話番号：</h4>
    090-7757-3965 -->
    <!-- <br />
    (受付時間 月曜日～金曜日 午前10時～午後6時まで) -->
    <h4>メールアドレス：</h4>
    info@recosele.com (受付時間 終日)
    <h4>商品の販売価格:</h4>
    各商品ページをご参照ください。
    <h4>商品以外の必要料金</h4>
    ・お申込み等にかかる通信費。
    <br />
    ・個人:ご注文の際の手数料:0円、送料:0円です。
    <br />
    ・法人：別途協議上で決定いたします。
    <h4>支払方法:</h4>
    クレジットカード決済・paypay決済
    <br />
    <h4>支払時期:</h4>
    ご注文時にお支払いが確定します。
    <br />
    <h4>商品の引渡時期:</h4>
    ご注文確定ページ上で表示された日時、受取場所に注文商品をお届けいたします。
    <br />
    <h4>返品・交換:</h4>
    ・品質に問題があった場合は、商品お届けの当日中に商品の写真を添えて上記のメールアドレス、またはLINE公式内の「お問い合わせ」までご連絡ください。
    <br />
    ・確認後お客様にご連絡の上、交換または代金の返金をさせていただきます。
    <br />
    ・商品に欠陥がある場合をのぞき、基本的には返品には応じません。
    <br />
    ・通信販売の性質上クーリングオフの適用はありません。
    <h4>当社からのキャンセル:</h4>
    当社において注文商品の在庫を確保することできない場合には、当社より個別契約の全部または一部をキャンセルさせていただきます。
    キャンセル分の商品について、既にご利用者様から当社に対して支払いがなされていた場合、
    当社よりご利用者様に対して当該キャンセル分の利用料金の返金を行います。
    <br />
    酒類の注文に関しましては、20歳未満の者に対して酒類を販売しませんので、年齢確認を
    実施して、確認か取れない場合はキャンセルとし、【東京都注文者限定販売】と表記のある
    酒類の販売に関しましては、酒類販売業免許に条件があるため東京都内に注文者住所がある
    方にしか販売できません。郵便番号にて住所確認をさせていただき対象外となる他の都道府県
    の方が購入されましても、販売はできませんのでキャンセルとして上記と同様に既にご利用者
    様から当社に対して支払いがなされていた場合、 当社よりご利用者様に対して当該キャンセル
    分の利用料金の返金を行います。
    <h4>酒類販売管理者標識</h4>
    【販売場の名称及び所在地】株式会社レコセレ
    <br />
    東京都大田区東糀谷5丁目16番21号 コジマ建物1階
    <br />
    【酒類販売管理者の氏名】邱 実
    <br />
    【酒類販売管理研修受講年月日】令和5年6月19日
    <br />
    【次回研修の受講期限】令和8年6月18日
    <br />
    【研修実施団体名】日本ボランタリーチェーン協会
  </v-container>
</template>

<script>
  export default {
    name: 'Rules',
  };
</script>

<!-- <template>
  <v-container style="max-width: 600px; margin: 0 auto">
    <div style="white-space: pre-line">
      {{ text }}
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Rules',
    data: () => ({
      text: `事業者の名称：
株式会社レコセレ

運営責任者：
邱 実

所在地：
〒104-0053
東京都中央区晴海3-10-1 Daiwa晴海ビル 2F

電話番号：
090-7757-3965 (受付時間 月曜日～金曜日 午前10時～午後6時まで）

メールアドレス：
info@recosele.com (受付時間 終日）

商品の販売価格:
各商品ページをご参照ください。

商品以外の必要料金
・お申込み等にかかる通信費
・個人:ご注文の際の手数料:一律198円(配送料込み）です。
法人：別途協議上で決定いたします。


支払方法:
クレジットカード決済・paypay決済

支払時期:
ご注文時にお支払いが確定いたします。

商品の引渡時期:
ご注文確定ページ上で表示された日時、受取場所に注文商品をお届けいたします。
ご利用者様からの注文のキャンセル:
ご利用者様は、ご注文確定当日に限り、注文のキャンセルを行うことができますが、 ご注文確定の翌日以降については、ご利用者様からのキャンセルをお請けすることはできません （利用料金が既に支払い済みであったとしても、返金に応じることはできません。）




返品・交換:
商品に欠陥がある場合をのぞき、基本的には返品には応じません。
品質に問題があった場合は、商品お届けの当日中に商品の写真を添えて上記のメールアドレス、またはLINE公式内の「お問い合わせ」までご連絡ください。
確認後お客様にご連絡の上、交換または代金の返金をさせていただきます。
通信販売の性質上クーリングオフの適用はありません。

当社からのキャンセル
当社において注文商品の在庫を確保することできない場合には、当社より個別契約の全部または一部をキャンセルさせていただきます。 キャンセル分の商品について、既にご利用者様から当社に対して支払いがなされていた場合、 当社よりご利用者様に対して当該キャンセル分の利用料金の返金を行います。
`,
    }),
  };
</script> -->
